<template>
    <div
        class="alert"
        :class="{
            'alert--primary': color === 'primary',
            'alert--success': color === 'success',
            'alert--danger': color === 'danger',
            'alert--warning': color === 'warning',
            'alert--light': color === 'light',
            'alert--gray': color === 'gray',
        }"
        role="alert"
    >
        <div class="flex">
            <font-awesome-icon
                :icon="icon"
                size="lg"
                class="mr-2 opacity-75"
            />

            <div class="flex-1">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faInfoCircle, faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons';

    library.add(faInfoCircle, faExclamationCircle);

    export interface Props {
        color?: 'primary' | 'success' | 'danger' | 'warning' | 'light' | 'gray';
    }

    const props = withDefaults(defineProps<Props>(), {
        color: 'primary',
    });

    const icon = computed(() => {
        switch (props.color) {
            case 'danger':
            case 'warning':
                return [ 'fad', 'exclamation-circle' ];
            default:
                return [ 'fad', 'info-circle' ];
        }
    });
</script>

<style lang="postcss">
    .alert {
        @apply block w-full relative px-4 py-3 mb-4 text-sm rounded text-white;

        &--primary {
            @apply bg-primary-500;
        }

        &--success {
            @apply bg-green-200 text-green-900;
            @apply dark:bg-green-700 dark:text-green-100;
        }

        &--danger {
            @apply bg-red-200 text-red-900;
            @apply dark:bg-red-700 dark:text-red-100;
        }

        &--warning {
            @apply bg-yellow-100 text-yellow-900;
            @apply dark:bg-yellow-700 dark:text-yellow-100;
        }

        &--gray {
            @apply bg-gray-200 text-gray-900;
            @apply dark:bg-gray-800 dark:text-gray-100;
        }
    }
</style>
